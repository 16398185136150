<div class="snackbar-container">
  <div class="snackbar-icon">
    <span class="material-icons">notifications</span>
  </div>
  <div class="snackbar-msg">
    <span>{{ data.msg }}</span>
  </div>
  <div class="snackbar-actions">
    <span class="snackbar-btn" (click)="onSubmit()"> {{ data.yesBtnTxt }} </span>
    <span class="snackbar-btn" (click)="onClose()"> {{ data.noBtnTxt }} </span>
  </div>
</div>
