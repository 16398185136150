import { Component, ChangeDetectionStrategy, Inject } from "@angular/core";
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";

@Component({
  selector: "ulaw-snack-bar",
  templateUrl: "./snack-bar.component.html",
  styleUrls: ["./snack-bar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackBarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private _snackBarRef: MatSnackBarRef<SnackBarComponent>,
  ) {}

  onSubmit(): void {
    this._snackBarRef.dismissWithAction();
  }

  onClose(): void {
    this._snackBarRef.dismiss();
  }
}
